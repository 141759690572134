import { batchActions } from 'redux-batch-enhancer';
import _get from 'lodash/get';

import env from '../../env';
import { get } from '../../api';
import store from '../store';

/**
 * Duck for fetching and dispatching star deal, all adverts, pick and mix, vouchers etc on page load
 */

function dispatchAll(data) {
  store.dispatch(batchActions([
    { type: 'frontend/products/FETCH_STAR_DEAL', payload: data.stardeal || null },
    { type: 'frontend/adverts/FETCH_ADVERTS', payload: data.images || [] },
    { type: 'frontend/pickAndMix/FETCH_PICK_AND_MIXES', payload: data.pickandmix || [] },
    { type: 'frontend/mystery/FETCH_MYSTERY', payload: data.mystery || [] },
    {
      type: 'frontend/trustpilot-api/TRUSTPILOT_API',
      payload: data.trustpilot || {
        total: env.trustpilot.total,
        score: {
          stars: env.trustpilot.stars,
          trustScore: env.trustpilot.trustScore,
        },
      },
    },
    { type: 'frontend/sales/DEALS_LIVE', payload: data.dealsLive },
    { type: 'frontend/publishers/PUBLISHER_RANKS', payload: data.publisherRanks },
    { type: 'frontend/franchises/FRANCHISE_RANKS', payload: data.franchiseRanks || [] },
    { type: 'frontend/live-display-types/LIVE_DISPLAY_TYPES', payload: data.liveDisplayTypes || {} },
  ], 'ALL'));
}

function dispatchPromotions(data) {
  store.dispatch(batchActions([
    { type: 'frontend/vouchers/FETCH_VOUCHERS', payload: data.vouchers || [] },
    { type: 'frontend/deliveries/FETCH_DELIVERIES', payload: data.deliveries || [] },
    { type: 'frontend/product/FREE_PRODUCTS', payload: data.freeProducts || [] },
    { type: 'frontend/product/SELECTABLE_FREE_PRODUCTS', payload: _get((data.freeProducts || []).find(fp => fp.free_redeem_code), 'products[0].freegames', []) },
    { type: 'frontend/sales/LIVE_SALES', payload: data.saleRecords || [] },
    { type: 'frontend/sales/MAJOR_SALE', payload: _get(data, 'saleRecords', []).find(sale => sale.majorSale) },
  ], 'ALL'));
}

export default function fetchAll(dispatch) {
  let language = 'en';
  if (window.locale) {
    language = window.locale;
  }

  return Promise.all([
    get(dispatch, `/all/${language}`, true)
      .then(({ data }) => dispatchAll(data, dispatch)),
    get(dispatch, `/all-promotions/${language}`, true)
      .then(({ data }) => dispatchPromotions(data, dispatch)),
  ]);
}
