import _get from 'lodash/get';

import env from '../../env';
import { get, post } from '../../api';
import store from '../store';
import { setOne, removeOne } from '../../Helpers/Cookie/CookieUtils';
import { amplitudeConsent } from '../amplitude';

const COOKIE_OPEN = 'frontend/cookie/COOKIE_OPEN';
const COOKIE_CONSENT = 'frontend/cookie/COOKIE_CONSENT';
const COOKIE_RESET = 'frontend/cookie/COOKIE_RESET';

export function openCookieCollapsible() {
  return { type: COOKIE_OPEN, payload: true };
}

export function closeCookieCollapsible() {
  return { type: COOKIE_OPEN, payload: false };
}

export function setConsent(payload) {
  setOne('CookieConsent', payload, { secure: env.appProtocol === 'https', expires: 365 }, true);
  store.dispatch(closeCookieCollapsible());
  amplitudeConsent(_get(payload, 'statistics', false));
  return { type: COOKIE_CONSENT, payload };
}

export function hydrateConsent(payload) {
  amplitudeConsent(_get(payload, 'statistics', false));
  return { type: COOKIE_CONSENT, payload };
}

export async function fetchCookieData() {
  const { response, data } = await get(store.dispatch, '/cookie/data-fetch');
  if (response.ok) {
    return data;
  }

  return {};
}

export function removeConsent() {
  removeOne('CookieConsent');
  store.dispatch(openCookieCollapsible());
  return { type: COOKIE_RESET, payload: null };
}

// Allow tag manager to Set cookie requested by frontend (for cj event id in safari)
export function setServerSideCookie(name, value, options) {
  return post(store.dispatch, '/cookie/set-cookie', { name, value, options });
}
