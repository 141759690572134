import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import floor from 'lodash/floor';

import { currencyCode } from '../../Helpers/Geo/data';
import { get as apiGet, post, put, del } from '../../api';
import { universalErrorSet } from './universal-error';
import {
  toggleUniversalErrorModal,
  toggleCheckoutModal,
} from './modal';
import store from '../store';
import { ERROR_MESSAGE } from './error-messages';
import State from '../../Helpers/State';
import { setCartMessage, resetCartMessage, CART_MESSAGE_DEFAULT, ITEM_UNAVAILABLE, E1 } from './cart-message';

const FETCH_CART = 'frontend/cart/FETCH_CART';
const EMPTY_CART = 'frontend/cart/EMPTY_CART';
const ADD_CART = 'frontend/cart/ADD_CART';
const REMOVE_CART = 'frontend/cart/REMOVE_CART';
const PRECHECK_PASS = 'frontend/cart/PRECHECK_PASS';
const ADD_ALL = 'frontend/cart/ADD_ALL';
const REMOVE_ALL = 'frontend/cart/REMOVE_ALL';
const CART_UPSELL = 'frontend/cart/CART_UPSELL';
const SET_REMOVAL_DELAY = 'frontend/cart/SET_REMOVAL_DELAY';
const CANCEL_REMOVE_CART = 'frontend/cart/CANCEL_REMOVE_CART';
const REMOVE_CART_DELAY_CLEANUP = 'frontend/cart/REMOVE_CART_DELAY_CLEANUP';

const getCartItemsSlugs = items => (items || []).map(i => i.slug);

export function fetchCart() {
  return dispatch => apiGet(dispatch, '/basket')
    .then(({ response, data }) => {
      if (response && response.ok && data) {
        dispatch({
          type: FETCH_CART,
          payload: { data, allSlugs: getCartItemsSlugs(data) },
        });
      }
    });
}

const decorateCart = cartData => cartData.map((item) => {
  const price = get(item, 'price', []);
  let actualPrice = get(price, currencyCode, 0);
  const currentDiscount = get(item, 'current_discount', 0);
  const percent = get(currentDiscount, 'percent', 0);
  const type = get(item, 'type', 'game');
  if (type !== 'bundle' && percent > 0 && percent < 1) {
    actualPrice *= 1 - percent;
  }
  return { ...item, local_price: floor(actualPrice) };
});

export function doAddToCart(
  push, productId, tierIndex, popup, productSlug, quantity, payWhatYouWant,
) {
  return async (dispatch) => {
    const { response, data } = await post(
      dispatch, `${!isEmpty(payWhatYouWant) ? '/basket/pay-what-you-want' : '/basket'}`, {
        productSlug, productId, tierIndex, quantity, payWhatYouWant,
      },
    );

    resetCartMessage();

    const pageId = State.getPageId() || '';

    if (response.ok) {
      let matchingProduct = data[0];

      if (!productId && productSlug && Array.isArray(data)) {
        matchingProduct = data.filter(product => product.slug === productSlug)[0];
      }
      const updatedCart = decorateCart(data);
      const productAdded = data[data.length - 1];

      const payload = {
        productName: productAdded.name,
        data: updatedCart,
        cartBefore: State.getCartItemsString(),
        allSlugs: getCartItemsSlugs(data),
        userId: State.getUserId(),
        pageId,
        itemAddedId: productId || matchingProduct._id,
        addingLoader: false,
        itemsAddedIds: [],
      };
      dispatch({ type: ADD_CART, payload });

      if (popup) {
        if (
          !includes(pageId, '/pick-and-mix/') &&
          pageId !== '/cart'
        ) {
          store.dispatch(toggleCheckoutModal());
        }

        if (includes(pageId, '/pick-and-mix/')) {
          push('/cart');
        }
      }

      if (!popup && pageId !== '/cart') {
        push('/cart');
      }
      return false;
    }

    const onProductPage = (
      pageId.indexOf('/game/') !== -1 ||
      pageId.indexOf('/bundle/') !== -1 ||
      pageId.indexOf('/dlc/') !== -1
    );

    if (onProductPage) {
      if (data && data.status === 'E9') {
        return false;
      }

      dispatch({ type: ERROR_MESSAGE, payload: data });
    }

    if (pageId === '/cart') {
      setCartMessage({
        astatus: get(data, 'status', CART_MESSAGE_DEFAULT),
        amessage: get(data, 'message', ''),
        adata: get(data, 'data', {}),
      });
      return false;
    }

    universalErrorSet({
      status: get(data, 'status', CART_MESSAGE_DEFAULT),
      error: get(data, 'message', ''),
    });

    // we don't want to display the error modal when a user is adding a product already in the cart

    store.dispatch(toggleUniversalErrorModal());
    return false;
  };
}

// Legacy function that used to do giveaway checks before add to cart api call
export function addToCart(
  push, productId, tierIndex, popup, quantity = 1, payWhatYouWant,
) {
  store.dispatch(doAddToCart(push, productId, tierIndex, popup, null, quantity, payWhatYouWant));
  return { type: PRECHECK_PASS, payload: null };
}

export function removeFromCart(productId, tierIndex) {
  return async (dispatch) => {
    const { response, data } = await put(dispatch, '/basket', { productId, tierIndex });
    if (!response.ok) throw Error(response.statusText);

    const updatedCart = decorateCart(data);

    const payload = {
      data: updatedCart,
      cartBefore: State.getCartItemsString(),
      userId: State.getUserId(),
      pageId: State.getPageId(),
      allSlugs: getCartItemsSlugs(data),
      // Used for amplitude, not sure why the extra details are needed for remove cart 🤷‍♂️
      cartBeforeEntire: State.getCartItems().map(item => ({
        currency: currencyCode,
        price: get(item, 'out.price'),
        product_name: item.name,
        product_id: item.pid,
        discount_price: get(item, 'out.discountPrice', null),
      })),
    };

    dispatch({ type: REMOVE_CART, payload });
  };
}

// Creates a utility function over the standard removeFromCart function to add a dealy to the function
export function removeFromCartWithDelay(productId, tierIndex) {
  return (dispatch, getState) => {
    const timeoutId = setTimeout(() => {
      const { removalDelays } = getState().cart;

      if (!removalDelays[productId]?.canceled) {
        removeFromCart(productId, tierIndex)(dispatch, getState);

        dispatch({
          type: REMOVE_CART_DELAY_CLEANUP,
          productId,
        });
      }
    }, 3000);

    dispatch({
      type: SET_REMOVAL_DELAY,
      productId,
      timeoutId,
    });
  };
}

export function cancelRemoveFromCart(productId) {
  return (dispatch, getState) => {
    const { removalDelays } = getState().cart;

    if (removalDelays[productId]) {
      clearTimeout(removalDelays[productId].timeoutId);

      dispatch({ type: CANCEL_REMOVE_CART, productId });
    }
  };
}

export function addToCartFromPixAndMix(productId, productSlug) {
  return async (dispatch) => {
    const { response, data } = await post(
      dispatch, '/basket', { productSlug, productId },
    );
    const astatus = get(data, 'status', '');
    const amessage = get(data, 'message', '');
    if (astatus === ITEM_UNAVAILABLE || astatus === E1) {
      dispatch({ type: ERROR_MESSAGE, payload: amessage });
      universalErrorSet({
        status: astatus,
        error: `Error: ${amessage}`,
      });
      return store.dispatch(toggleUniversalErrorModal());
    }

    const updatedCart = decorateCart(data);

    let matchingProduct = data[0];

    if (!productId && productSlug && Array.isArray(data)) {
      matchingProduct = data.filter(product => product.slug === productSlug)[0];
    }

    const payload = {
      data: updatedCart,
      cartBefore: State.getCartItemsString(),
      userId: State.getUserId(),
      pageId: State.getPageId(),
      itemAddedId: productId || matchingProduct._id,
      addingLoader: false,
      allSlugs: getCartItemsSlugs(data),
      isPickAndMix: true,
      itemsAddedIds: [],
    };

    if (!response.ok) throw Error(response.statusText);

    dispatch({ type: ADD_CART, payload });

    return null;
  };
}

export function emptyCart() {
  return async (dispatch) => {
    const url = '/basket';

    const { response } = await del(dispatch, url);

    if (response.ok) {
      dispatch({ type: EMPTY_CART });
    }
  };
}

/**
 * Add a list of products to basket in one request
 * @param {Array.String} products product Ids
 */
export function addAll(products, showCheckoutModal) {
  return async (dispatch) => {
    const { response, data } = await post(
      dispatch, '/basket/setmany', { products },
    );

    if (!response.ok) {
      universalErrorSet({
        status: get(data, 'status', CART_MESSAGE_DEFAULT),
        error: get(data, 'message', ''),
      });

      store.dispatch(toggleUniversalErrorModal());
      return false;
    }

    if (showCheckoutModal) store.dispatch(toggleCheckoutModal());

    const updatedCart = decorateCart(data);

    const itemsAddedIds = data
      .filter(i => products.includes(i._id) || products.includes(i.slug))
      .map(i => i._id);

    const pageId = State.getPageId() || '';
    const payload = {
      data: updatedCart,
      cartBefore: State.getCartItemsString(),
      allSlugs: getCartItemsSlugs(data),
      userId: State.getUserId(),
      itemsAddedIds,
      pageId,
    };

    return dispatch({ type: ADD_ALL, payload });
  };
}

/**
 * Remove a list of products from basket in one request
 * @param {Array.String} products product Ids
 */
export function removeAll(products) {
  return async (dispatch) => {
    const { response, data } = await post(
      dispatch, '/basket/removemany', { products },
    );

    const updatedCart = decorateCart(data);

    if (!response.ok) {
      universalErrorSet({
        status: get(data, 'status', CART_MESSAGE_DEFAULT),
        error: get(data, 'message', ''),
      });

      store.dispatch(toggleUniversalErrorModal());
      return false;
    }

    const pageId = State.getPageId() || '';
    const payload = {
      data: updatedCart,
      cartBefore: State.getCartItemsString(),
      allSlugs: getCartItemsSlugs(data),
      userId: State.getUserId(),
      pageId,
    };

    return dispatch({ type: REMOVE_ALL, payload });
  };
}


// Creates a utility function for the remove all for the PNM bundles as they dont have a productId we use the slug
export function removePNMFromCartWithDelay(slug, products) {
  return (dispatch, getState) => {
    const timeoutId = setTimeout(() => {
      const { removalDelays } = getState().cart;

      if (!removalDelays[slug]?.canceled) {
        removeAll(products)(dispatch, getState);

        dispatch({
          type: REMOVE_CART_DELAY_CLEANUP,
          productId: slug,
        });
      }
    }, 5000);

    dispatch({
      type: SET_REMOVAL_DELAY,
      productId: slug,
      timeoutId,
    });
  };
}

/**
 * Dispatch cart upsell event
 * @param {Array.String} products product Ids
 */
export function cartUpsellEvent(cart, currencyCodePassed) {
  const cartItems = get(cart, 'items', []);
  const cartItemsSend = [];
  cartItems.forEach((item) => {
    let price = get(item.price, currencyCodePassed, 0);
    if (item.out) {
      price = (item.out.discountPrice / 100) * item.quantity;
    }
    if (item.type === 'bundle' && !item.is_srp_bundle) {
      price = (get(item.price, currencyCodePassed, 0) / 100) * item.quantity;
    }
    if (item.out && item.out.couponPrice > 0) {
      price = (item.out.couponPrice / 100) * item.quantity;
    }
    if (item.pickAndMix) {
      price = (item.pickAndMix.price) * item.quantity;
    }
    const newItem = {};
    newItem[item._id] = price;
    cartItemsSend.push(newItem);
  });
  const payload = {
    cartCurrency: currencyCodePassed,
    cartTotal: cart.total || 0,
    cartItems: cartItemsSend,
  };
  return store.dispatch({ type: CART_UPSELL, payload });
}
