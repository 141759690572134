import Cookies from 'js-cookie';

import store from '../store';
import tests from '../../config/split-tests';
import isBot from '../../Helpers/SEO/is-bot';
import nrWrap from '../../Helpers/newrelic';

const SPLIT_TEST = 'frontend/split-test/SPLIT_TEST';

const SPLIT_CACHE = {};
const PREFIX = 'PUSHTELL-';


/**
 * Default values for all split tests (if not explicitly set)
 * @type {Object}
 */
const defaults = {
  variants: [
    {
      name: 'control',
      weight: 50,
    },
    {
      name: 'challenger',
      weight: 50,
    },
  ],
  prerenderChallenger: false,
  api: false, // Send as header on API requests
};


/**
 * Format a split test from config
 * @param  {String|Object} test
 * @return {Object}
 */
const format = (test) => {
  if (typeof test === 'string') {
    return Object.assign({}, defaults, { name: test });
  }

  return Object.assign({}, defaults, test);
};

/**
 * Validate split test config
 * @param  {Object} test
 * @throws {Error}        When test is invalid
 */
const validate = (test) => {
  if (!test.name) {
    throw new Error('Split test must have a name');
  }

  if (!Array.isArray(test.variants) || test.variants.length < 2) {
    throw new Error(`Split test ${test.name} - must have at least 2 variants`);
  }

  const total = test.variants.map(v => v.weight).reduce((a, b) => a + b, 0);

  if (total !== 100) {
    throw new Error(`Split test ${test.name} - variant weights must have a sum of 100`);
  }
};

/**
 * Find a split test from its name
 * @param  {String} name Split test name
 * @return {Object}      Found split test
 * @throws {Error}       When test is now found
 */
export function getByName(name) {
  const formattedSplitTests = tests.map(format);
  const found = formattedSplitTests.find(test => test.name === name);

  if (found) {
    return found;
  }

  throw new Error(`Split Test ${name} not found`);
}

/**
 * Remove a split test from cache and local storage
 * @param  {String} name    Name of split test
 */
function removeSplit(name) {
  delete SPLIT_CACHE[name];
  localStorage.removeItem(`${PREFIX}${name}`);
}

/**
 * Retrieve a split test from cache or local storage
 * @param  {String} name    Name of split test
 * @return {String|Null}
 */
export function retrieveSplit(name) {
  if (typeof SPLIT_CACHE[name] === 'string') {
    return SPLIT_CACHE[name];
  }
  return localStorage.getItem(`${PREFIX}${name}`) || null;
}

/**
 * Save a split test variange to cache and local storage
 * @param  {String} name    Name of split test
 * @param  {String} variant Nae of variange
 */
export function saveSplit(name, variant) {
  SPLIT_CACHE[name] = variant;
  localStorage.setItem(`${PREFIX}${name}`, variant);
}

/**
 * Hydrate a split test variant from local storage to local cache object
 * @param  {String} name     Name of split test
 */
function hydrateSplit(name) {
  const value = retrieveSplit(name);
  SPLIT_CACHE[name] = value;
  nrWrap(newrelic => newrelic.setCustomAttribute(`split-${name}`, value));
}

/**
 * Dispatch split test side to redux/snowplow
 */
function reportSplitTrigger(experimentName, variant) {
  store.dispatch({ type: SPLIT_TEST, payload: { experimentName, variant } });
}

/**
 * Assign or retrieve an A/B split test side
 * @param  {String} name    Name of split test
 * @return {String}
 */
export function GetSplit(name) {
  const test = getByName(name);

  if (!test) {
    throw new Error(`Split Test ${name} not found`);
  }

  const variantNames = test.variants.map(v => v.name);
  const variantWeights = test.variants.map(v => v.weight / 100);

  let splitVariant = retrieveSplit(name);

  if (!splitVariant || !variantNames.includes(splitVariant)) {
    const cdf = variantWeights.map((sum => value => sum += value)(0)); // eslint-disable-line

    if (Cookies.get('IsCalibre') || window.isNRSynthetic) {
      splitVariant = variantNames[variantNames.length - 1];
    } else if (isBot()) {
      if (test.prerenderChallenger) {
        splitVariant = variantNames[variantNames.length - 1];
      } else {
        splitVariant = variantNames[0];
      }
    } else {
      const rand = Math.random();
      splitVariant = variantNames[cdf.filter(el => rand >= el).length];

      // Skip sp event for bots and synthetics
      reportSplitTrigger(name, splitVariant);
    }

    saveSplit(name, splitVariant);
  }

  return splitVariant;
}

/**
 * Is the given split test set to challenger
 * @param   {String}  name Split test name
 * @return  {Boolean}
 */
export function IsChallenger(name) {
  return GetSplit(name) === 'challenger';
}

/**
 * Is the given split test set to control
 * @param   {String}  name Split test name
 * @return  {Boolean}
 */
export function IsControl(name) {
  return GetSplit(name) === 'control';
}

/**
 * Validate test config then build up split test cache
 * and clear out old split tests from user's local storage
 */
export function initialiseSplitTests() {
  const formattedSplitTests = tests.map(format);
  formattedSplitTests.forEach(validate);
  const splitTestsNames = formattedSplitTests.map(t => t.name);

  const storedSplitTests = Object.keys(localStorage)
    .filter(key => key.startsWith(PREFIX))
    .map(key => key.replace(PREFIX, ''));

  const inactiveSplitTests = storedSplitTests.filter(key => !splitTestsNames.includes(key));
  const activeSplitTests = storedSplitTests.filter(key => splitTestsNames.includes(key));

  inactiveSplitTests.forEach(removeSplit);
  activeSplitTests.forEach(hydrateSplit);
}
